import React from "react"
import Slider from "react-slick"

interface Props {
  children: JSX.Element[]
}

export default function SimpleSlider({ children }: Props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    fade: true,
    arrows: false,
  }
  return (
    <div className="container">
      <Slider {...settings}>
        {children.map((slide, i) => (
          <div key={i}>{slide}</div>
        ))}
      </Slider>
    </div>
  )
}
