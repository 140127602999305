import React, { Dispatch, SetStateAction, useState } from "react"
import { Form, Button } from "react-bootstrap"
import axios from "axios"

interface Props {
  setCommunityFormDisplay: Dispatch<SetStateAction<boolean>>
}

const hostUrl = process.env.GATSBY_BACKEND_HOST

export default function CommunityForm({ setCommunityFormDisplay }: Props) {
  const [formState, setFormState] = useState({
    validated: false,
    success: false,
    submitted: false,
    error: "",
    email: "",
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setFormState({ ...formState, validated: true })
      axios
        .post(`${hostUrl}/community-waitlist/add-contact`, {
          email: formState.email,
        })
        .then(res => {
          setFormState({ ...formState, success: true, submitted: true })
        })
        .catch(err => {
          setFormState({ ...formState, error: err, submitted: true })
        })
    }

    setFormState({ ...formState, validated: true })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  return (
    <div className="modal-bg" onClick={() => setCommunityFormDisplay(false)}>
      <Form
        onClick={e => e.stopPropagation()}
        className="normal-form"
        noValidate
        validated={formState.validated}
        onSubmit={handleSubmit}
      >
        <h2 className="mb-4">Our Community Page is under development.</h2>
        <h4 className="mb-4">
          Join the waitlist to be the first to get notified about the launch.
        </h4>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={formState.email}
            name="email"
            onChange={handleChange}
            required
          />
        </Form.Group>

        {formState.submitted ? (
          formState.error !== "" ? (
            <div>
              <p style={{ color: "red" }}>
                Uh oh, there was a problem adding you!
              </p>
              <Button onClick={() => setCommunityFormDisplay(false)}>
                Close
              </Button>
            </div>
          ) : (
            <div>
              <p>Success!</p>
              <Button onClick={() => setCommunityFormDisplay(false)}>
                Close
              </Button>
            </div>
          )
        ) : (
          <div className="two-input-line">
            <Button
              variant="secondary"
              onClick={() => setCommunityFormDisplay(false)}
            >
              Cancel
            </Button>

            <Button type="submit" variant="primary">
              Submit
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}
