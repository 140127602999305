import React, { Dispatch, SetStateAction, useState } from "react"
import { Form, Button } from "react-bootstrap"
import axios from "axios"
interface Props {
  setWaitlistDisplay: Dispatch<SetStateAction<boolean>>
}

const hostUrl = process.env.GATSBY_BACKEND_HOST

export default function WaitlistModal({ setWaitlistDisplay }: Props) {
  const [formState, setFormState] = useState({
    validated: false,
    success: false,
    submitted: false,
    error: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setFormState({ ...formState, validated: true })
      axios
        .post(`${hostUrl}/waitlist/add-contact`, {
          email: formState.email,
          company: formState.company,
          firstName: formState.firstName,
          lastName: formState.lastName,
        })
        .then(res => {
          setFormState({ ...formState, success: true, submitted: true })
        })
        .catch(err => {
          setFormState({ ...formState, error: err.statusText, submitted: true })
        })
    }

    setFormState({ ...formState, validated: true })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  return (
    <div className="modal-bg" onClick={() => setWaitlistDisplay(false)}>
      <Form
        onClick={e => e.stopPropagation()}
        className="normal-form"
        noValidate
        validated={formState.validated}
        onSubmit={handleSubmit}
      >
        <h2 className="text-center mb-4">
          Data Science and Analytics Platform
        </h2>
        <h3 className="text-center mb-4">Coming Soon</h3>
        <h4 className="text-center mb-5">
          Sign up below to join the{" "}
          <b style={{ borderBottom: "1px solid #1565c0" }}>waitlist.</b>
        </h4>
        <div className="two-input-line">
          <Form.Group controlId="formBasicFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              name="firstName"
              value={formState.firstName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formState.lastName}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </div>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={formState.email}
            name="email"
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Company</Form.Label>
          <Form.Control
            type="text"
            placeholder="Company"
            value={formState.company}
            name="company"
            onChange={handleChange}
            required
          />
        </Form.Group>
        {formState.submitted ? (
          formState.error ? (
            <div>
              <p style={{ color: "red" }}>
                Uh oh, there was a problem adding you!
              </p>
              <Button onClick={() => setWaitlistDisplay(false)}>Close</Button>
            </div>
          ) : (
            <div>
              <p>Success!</p>
              <Button onClick={() => setWaitlistDisplay(false)}>Close</Button>
            </div>
          )
        ) : (
          <div className="two-input-line">
            <Button
              variant="secondary"
              onClick={() => setWaitlistDisplay(false)}
            >
              Cancel
            </Button>

            <Button type="submit" variant="primary">
              Submit
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}
